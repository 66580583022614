import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#79D04D",
      d: "M21.25 11.313 14 18.625l-3.312-3.312c-.376-.376-1.063-.376-1.438 0-.375.374-.375 1.062 0 1.437l4 4c.188.188.438.25.75.25.25 0 .5-.062.688-.25l8-8c.375-.375.375-1.062 0-1.437-.375-.376-1.063-.376-1.438 0M16 0C7.125 0 0 7.188 0 16c0 8.875 7.125 16 16 16 8.813 0 16-7.125 16-16 0-8.812-7.187-16-16-16m0 30C8.25 30 2 23.75 2 16 2 8.313 8.25 2 16 2c7.688 0 14 6.313 14 14 0 7.75-6.312 14-14 14"
    }, null, -1)
  ])))
}
export default { render: render }